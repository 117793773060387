import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom'
import { ThemeProps } from "../theme";
import { UserResponse } from '../api/api_types';

interface SecurityCodesCardProps extends ThemeProps {
  userInfo: UserResponse
}

export const SecurityCodesCard = ({ classes, userInfo }: SecurityCodesCardProps) => 
  <Card className={classes.card}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Security Codes
      </Typography>
      <Typography component="p">
        Security codes can be used on devices where you can't use a security key.
      </Typography>
    </CardContent>
    <CardActions>
      <Button component={Link} to="/sc" size="small" color="primary">
        Create
      </Button>
    </CardActions>
  </Card>