import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import React from 'react';
import { Link } from 'react-router-dom'
import { ThemeProps } from "../theme";
import { UserResponse } from '../api/api_types';

interface SecurityKeysCardProps extends ThemeProps {
  userInfo: UserResponse
}

const EnrollLink = (props: any) => <Link to="/enroll/code" {...props} />

export const SecurityKeysCard = ({ classes, userInfo }: SecurityKeysCardProps) =>
  <Card className={classes.card}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Security Keys
      </Typography>
      <Typography component="p">
        After entering your password, you’ll be asked for a second verification step.
      </Typography>
      <List className={classes.card_list}>
        {userInfo.credentials.map(credential => (
          <div>
            <ListItem>
              <Avatar className={classes.security_key_icon}>
                <VpnKeyIcon />
              </Avatar>
              <ListItemText primary={credential.name} secondary={
                <React.Fragment>
                  <div>
                    Last used 2019-03-02
                  </div>
                  <div>
                    Added 2018-04-05
                  </div>
                </React.Fragment>
              } />
            </ListItem>
            <Divider variant="inset" />
          </div>
        ))}
      </List>
    </CardContent>

    <CardActions>
      <Button component={EnrollLink} size="small" color="primary">
        Add Security Key
      </Button>
    </CardActions>
  </Card>
