import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProps } from "../theme";
import { useWebauthnService } from '../webauthn';
import { Paper, Avatar, Typography } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import { useApiService } from '../api/api_client';

interface MatchParams {
  id: string;
}

interface ConfirmAuthorizeKeyProps extends ThemeProps, RouteComponentProps<MatchParams> { }

export const ConfirmAuthorizeKey = ({ classes, match }: ConfirmAuthorizeKeyProps) => {
  const [expiresAt, setExpiresAt] = useState("")
  const api = useApiService()
  const webauthn = useWebauthnService()
  const shortKeyId = match.params.id;

  useEffect(() => {
    api.GetConfirmAuthorizedKey(shortKeyId)
      .then(res => {
        const { challenge, rp_id, credential_ids, token } = res.authenticate!
        webauthn.startAssertion({
          challenge, rp_id, credential_ids,
          onCredential: (credential => {
            api.PostConfirmAuthorizedKey(shortKeyId, { token, credential })
              .then(res => {
                setExpiresAt(res.result!.expires_at)
              });
          }),
          onNotSupported: () => { },
          onAssertionError: (error) => { },
        })
      })
  }, [api, shortKeyId, webauthn])

  return (
    <main className={classes.dialog}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Confirm SSH Key
        </Typography>
        <Typography component="p" variant="body1">
          {expiresAt}
        </Typography>
      </Paper>
    </main>
  )
}
