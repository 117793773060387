import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProps } from "../theme";

export class BackendList extends React.Component<ThemeProps, {}> {
  constructor(props: ThemeProps) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
      </main>
    );
  }
}