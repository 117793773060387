import React, { Component } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface SecondFactorFormProps {
  onUseSecurityKey: (() => void);
  onUseSecurityCode: (() => void);
}

export class SecondFactorForm extends Component<SecondFactorFormProps> {
  render() {
    const { onUseSecurityKey, onUseSecurityCode } = this.props
    return (
      <div>
        <Typography component="p" variant="body1">
          Please select how you want to sign in.
        </Typography>
        <List component="nav" aria-label="select second factor authentication">
          <ListItem button onClick={() => onUseSecurityKey()}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary="Use your security key" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => onUseSecurityCode()}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Enter an 8-digit security code" />
          </ListItem>
        </List>
      </div>
    )
  }
}
