import { TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import React, { Component } from 'react';

interface EnrollLoginState {
  username: string;
  password: string;
  code: string;
}

export interface EnrollLoginSubmitDetails {
  username: string;
  password: string;
  code: string;
}

interface EnrollLoginFormProps {
  classes: {
    main: string;
    paper: string;
    avatar: string;
    form: string;
    submit: string;
    spinner: string;
  };
  error?: string
  onSubmit: ((details: EnrollLoginSubmitDetails) => void);
}

export class EnrollLoginForm extends Component<EnrollLoginFormProps, EnrollLoginState> {
  constructor(props: EnrollLoginFormProps) {
    super(props)
    let code = new URLSearchParams(window.location.search).get("code")
    this.state = { username: "", password: "", code: code || "" }
  }
  handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ username: e.target.value });
  }
  handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: e.target.value });
  }
  handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ code: e.target.value });
  }
  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onSubmit({
      username: this.state.username,
      password: this.state.password,
      code: this.state.code,
    })
  }

  render() {
    const { classes, error } = this.props
    const errPassword = error === "wrong_username_password"
    const errCode = error === "wrong_code"
    return (
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enroll new key
        </Typography>
        <form className={classes.form} method="POST" onSubmit={this.handleSubmit.bind(this)}>
          <TextField
            required
            fullWidth
            id="username"
            label="Username"
            margin="normal" variant="outlined"
            value={this.state.username} onChange={this.handleUsernameChange.bind(this)} />
          <TextField
            required
            error={errPassword}
            fullWidth
            type="password"
            id="password"
            label="Password"
            autoComplete="current-password"
            margin="normal" variant="outlined"
            value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
          <TextField
            required
            error={errCode}
            fullWidth
            id="code"
            label="Code"
            margin="normal" variant="outlined"
            value={this.state.code} onChange={this.handleCodeChange.bind(this)} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>Continue</Button>
        </form>
      </Paper>
    )
  }
}
