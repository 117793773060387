import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProps } from "../theme";
import { EnrollKeyForm } from "./enroll_key";
import { EnrollLoginForm, EnrollLoginSubmitDetails } from "./enroll_login";
import { EnrollFinalizeSubmitDetails, EnrollFinalize } from "./enroll_finalize";
import { ApiServiceContext } from '../api/api_client';
import { AttestationCredential, EnrollInitializeResponse, EnrollRequest } from '../api/api_types';

interface State {
    enrollRequest?: EnrollRequest
    credential?: AttestationCredential
    error?: EnrollInitializeResponse['error']
}

export class Enroll extends React.Component<ThemeProps, State> {
    static contextType = ApiServiceContext
    context: React.ContextType<typeof ApiServiceContext>;

    constructor(props: ThemeProps) {
        super(props)
        this.state = {}
    }

    handleAuthenticate(details: EnrollLoginSubmitDetails) {
        const api = this.context!
        api.InitializeEnroll(details)
            .then(res => {
                if (res.error !== undefined) {
                    console.log("Error initiating", res)
                    this.setState({ error: res.error })
                } else {
                    console.log("Got enroll request", res)
                    this.setState({ enrollRequest: res.enroll_request })
                }
            });
    }

    handleEnroll(credential: AttestationCredential) {
        this.setState({ credential })
    }

    handleFinalize(form: EnrollFinalizeSubmitDetails) {
        let { enrollRequest, credential } = this.state
        let { name } = form
        const api = this.context!
        api.FinishEnroll({ token: enrollRequest!.token, name, credential: credential! })
            .then(res => {
                if (res.error) {
                    alert("Failed to enroll");
                } else {
                    alert("Enrolled!")
                }
            });
    }

    render() {
        const { classes } = this.props;
        const { credential, enrollRequest } = this.state
        console.log("Enroll request:", enrollRequest)
        console.log("Credential", credential)

        const attestationReq = {
            enroll: enrollRequest!,
            onCredential: this.handleEnroll.bind(this),
            onError: () => { },
            onNotSupported: () => { },
        }
        return (
            <main className={classes.dialog}>
                <CssBaseline />
                {enrollRequest && !credential && <EnrollKeyForm classes={classes} request={attestationReq} />}
                {enrollRequest && credential && <EnrollFinalize classes={classes} onSubmit={this.handleFinalize.bind(this)} />}
                {!credential && !enrollRequest && <EnrollLoginForm classes={classes} onSubmit={this.handleAuthenticate.bind(this)} />}
            </main>
        );
    }
}