import React, { useEffect } from 'react';
import { useWebauthnService } from '../webauthn';
import { Typography } from '@mui/material';
import { AssertionCredential } from '../api/api_types';

export interface SecurityKeyRequest {
  challenge: string;
  credential_ids: string[];
  rp_id: string;
}

interface SecurityKeyFormProps {
  request: SecurityKeyRequest
  onCredential: ((credential: AssertionCredential) => void)
  onAssertionError: ((error: any) => void)
  onNotSupported: (() => void)
}

export const SecurityKeyForm = ({request, onCredential, onAssertionError, onNotSupported } : SecurityKeyFormProps) => {
  const webauthn = useWebauthnService()

  useEffect(() => {
    const { challenge, credential_ids, rp_id } = request
    webauthn.startAssertion({
      challenge, credential_ids, rp_id,
      onCredential,
      onNotSupported, onAssertionError,
    })
  }, [onAssertionError, onCredential, onNotSupported, request, webauthn])

  return (
    <div>
      <Typography component="p" variant="body1">
        Please touch your key
      </Typography>
    </div>
  )
}
