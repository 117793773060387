import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect } from 'react';
import { useWebauthnService, WebauthnAttestationRequest } from '../webauthn';
import { ThemeProps } from '../theme';

interface EnrollKeyProps extends ThemeProps {
  request: WebauthnAttestationRequest
}

export const EnrollKeyForm = ({classes, request} : EnrollKeyProps) => {
  const webauthn = useWebauthnService()

  useEffect(() => {
    webauthn.startAttestation(request)
  }, [webauthn, request])

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockIcon fontSize="large" />
      </Avatar>
      <Typography component="h1" variant="h5">
        Enroll new key
      </Typography>
      <CircularProgress className={classes.spinner} />
      <Typography component="h1" variant="subtitle1">
        Touch security key
      </Typography>
    </Paper>
  )
}
