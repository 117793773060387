import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProps } from '../theme';

interface SecurityCodeFormState {
  code: string;
}

interface SecurityCodeFormProps extends ThemeProps {
  onSubmit: ((code: string) => void);
}

export class SecurityCodeForm extends Component<SecurityCodeFormProps, SecurityCodeFormState> {
  constructor(props: SecurityCodeFormProps) {
    super(props)
    this.state = { code: "" }
  }
  handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ code: e.target.value });
  }
  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onSubmit(this.state.code)
  }

  render() {
    const { classes } = this.props
    return (
      <form className={classes.form} method="POST" onSubmit={this.handleSubmit.bind(this)}>
        <Typography component="p" variant="body1">
          Enter one of your 8-digit security codes to continue. Note that security codes expire after 24 hours and can only be used once.
        </Typography>
        <TextField
          autoFocus
          fullWidth
          id="username"
          label="Enter an 8-digit security code"
          margin="normal" variant="outlined"
          value={this.state.code} onChange={this.handleCodeChange.bind(this)} />
        <Button
          type="submit"
          color="primary"
          size="large"
          className={classes.textbutton}>Back</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          className={classes.submit}>Next</Button>
      </form>
    )
  }
}
