import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProps } from '../theme';

interface PasswordFormState {
  password: string;
}

interface PasswordFormProps extends ThemeProps {
  username: string;
  onSubmit: ((password: string) => void);
}

export class PasswordForm extends Component<PasswordFormProps, PasswordFormState> {
  constructor(props: PasswordFormProps) {
    super(props)
    this.state = { password: "" }
  }
  handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: e.target.value });
  }
  handleSubmit(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.props.onSubmit(this.state.password)
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome
        </Typography>
        <div>
          <Chip avatar={<Avatar>{this.props.username[0]}</Avatar>} label={this.props.username} variant="outlined" />
        </div>
        <TextField
          autoFocus
          fullWidth
          type="password"
          id="password"
          label="Password"
          margin="normal" variant="outlined"
          value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={this.handleSubmit.bind(this)}
          className={classes.submit}>Next</Button>
      </React.Fragment>
    )
  }
}
