import {createContext, useContext} from 'react';
import {EnrollCodeResponse, EnrollFinishRequest, EnrollFinishResponse, EnrollInitializeRequest, EnrollInitializeResponse, GenerateSecurityCodeRequest, GenerateSecurityCodeResponse, GetConfirmAuthorizedKeyResponse, PostConfirmAuthorizedKeyRequest, PostConfirmAuthorizedKeyResponse, RequestSecurityCodeResponse, SignInPasswordRequest, SignInResponse, SignInSecurityCodeRequest, SignInWebauthnRequest, UserResponse} from './api_types'

export interface ApiService {
  GetConfirmAuthorizedKey(shortKeyId: string):
      Promise<GetConfirmAuthorizedKeyResponse>;

  PostConfirmAuthorizedKey(
      shortKeyId: string, req: PostConfirmAuthorizedKeyRequest):
      Promise<PostConfirmAuthorizedKeyResponse>;

  SignInUsername(username: string): Promise<SignInResponse>;

  SignInPassword(req: SignInPasswordRequest): Promise<SignInResponse>;

  SignInSecurityCode(req: SignInSecurityCodeRequest): Promise<SignInResponse>;

  SignInWebauthn(req: SignInWebauthnRequest): Promise<SignInResponse>;

  RequestSecurityCode(): Promise<RequestSecurityCodeResponse>;

  GenerateSecurityCode(req: GenerateSecurityCodeRequest):
      Promise<GenerateSecurityCodeResponse>;

  GetUser(): Promise<UserResponse>;

  CreateEnrollCode(): Promise<EnrollCodeResponse>;

  InitializeEnroll(req: EnrollInitializeRequest):
      Promise<EnrollInitializeResponse>;

  FinishEnroll(req: EnrollFinishRequest): Promise<EnrollFinishResponse>;
}

export const realApiService: ApiService = {
  async GetConfirmAuthorizedKey(shortKeyId: string):
      Promise<GetConfirmAuthorizedKeyResponse> {
        const res = await fetch(`/api/authorized_key/${shortKeyId}/confirm`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
          }
        });
        return res.json();
      },

  async PostConfirmAuthorizedKey(
      shortKeyId: string, req: PostConfirmAuthorizedKeyRequest):
      Promise<PostConfirmAuthorizedKeyResponse> {
        const res = await fetch(`/api/authorized_key/${shortKeyId}/confirm`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(req)
        });
        return res.json()
      },

  async SignInUsername(username: string): Promise<SignInResponse> {
    const res = await fetch('/api/login/username', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({
        username,
      })
    });
    return res.json()
  },

  async SignInPassword(req: SignInPasswordRequest): Promise<SignInResponse> {
    const res = await fetch('/api/login/password', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },

  async SignInSecurityCode(
      req: SignInSecurityCodeRequest): Promise<SignInResponse> {
    const res = await fetch('/api/login/security_code', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },

  async SignInWebauthn(req: SignInWebauthnRequest): Promise<SignInResponse> {
    const res = await fetch('/api/login/webauthn', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },


  async RequestSecurityCode(): Promise<RequestSecurityCodeResponse> {
    const res = await fetch('/api/security_code/request', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({})
    });
    return res.json()
  },

  async GenerateSecurityCode(
      req: GenerateSecurityCodeRequest): Promise<GenerateSecurityCodeResponse> {
    const res = await fetch('/api/security_code/generate', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },

  async GetUser(): Promise<UserResponse> {
    const response = await fetch('/api/user');
    return await response.json();
  },

  async CreateEnrollCode(): Promise<EnrollCodeResponse> {
    const res = await fetch('/api/enroll/code', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({})
    });
    return res.json()
  },


  async InitializeEnroll(
      req: EnrollInitializeRequest): Promise<EnrollInitializeResponse> {
    const res = await fetch('/api/enroll/initate', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },

  async FinishEnroll(req: EnrollFinishRequest): Promise<EnrollFinishResponse> {
    const res = await fetch('/api/enroll/finish', {
      method: 'post',
      headers:
          {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(req)
    });
    return res.json()
  },
}

export const ApiServiceContext = createContext<ApiService|undefined>(undefined);

export const useApiService = () => {
  const context = useContext<ApiService|undefined>(ApiServiceContext)
  if (context === undefined) {
    throw new Error('ApiServiceContext must wrap this component')
  }
  return context
}
