import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom'
import { UserResponse } from '../api/api_types';
import { ThemeProps } from "../theme";

const MyLink = (props: any) => <Link to="/password" {...props} />

interface PasswordCardProps extends ThemeProps {
  userInfo: UserResponse
}

export class PasswordCard extends React.Component<PasswordCardProps, any> {
  render() {
    const { classes } = this.props
    return (
      <Card className={classes.card}>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Password
          </Typography>
          <Typography component="p">
            You last changed your password on Dec 6, 2011.
          </Typography>
        </CardContent>

        <CardActions>
          <Button component={MyLink} size="small" color="primary">
            Change Password
          </Button>
        </CardActions>
      </Card>
    )
  }
}
