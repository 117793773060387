import { TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import { ApiServiceContext } from '../api/api_client';
import { EnrollCodeResponse } from '../api/api_types';

interface EnrollCodeProps {
  classes: {
    dialog: string;
    main: string;
    paper: string;
    avatar: string;
    form: string;
    submit: string;
    spinner: string;
  };
}

interface EnrollCodeState {
  response?: EnrollCodeResponse
}

export class EnrollCode extends Component<EnrollCodeProps, EnrollCodeState> {
  static contextType = ApiServiceContext
  context: React.ContextType<typeof ApiServiceContext>;

  constructor(props: EnrollCodeProps) {
    super(props);
    this.state = {
      response: undefined
    }
  }
  componentDidMount() {
    const api = this.context!
    api.CreateEnrollCode()
      .then(response => {
        if (response.code) {
          this.setState({ response })
        }
      });
  }
  render() {
    const { classes } = this.props

    if (!this.state.response) {
      return <div>Loading...</div>
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Enroll a new security key
        </Typography>
          <Typography component="p">
            Use this link on the device where you want to enroll a new security key.
        </Typography>
          <form className={classes.form}>
            <TextField
              fullWidth
              id="link"
              label="Enroll Link"
              margin="normal" variant="outlined"
              value={this.state.response.url}
              InputProps={{
                readOnly: true,
              }} />
          </form>
          <div style={{ margin: "30px" }} >
            <QRCode size={300} value={this.state.response.url} />
          </div>
        </Paper>
      </main>
    )
  }
}
