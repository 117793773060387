import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProps } from "../theme";
import { UsernameForm } from "./username_form";
import { PasswordForm } from "./password_form";
import { SecondFactorForm } from './second_factor_form';
import Paper from '@mui/material/Paper';
import { SecurityCodeForm } from './security_code_form';
import { SecurityKeyForm, SecurityKeyRequest } from './security_key_form';
import { SignInResponse, SignInRequestSecondFactor, AssertionCredential } from '../api/api_types';
import { ApiServiceContext } from '../api/api_client';

enum UIState {
  UsernameForm = 1,
  PasswordForm = 2,
  SecondFactorForm = 3,
  SecurityCodeForm = 4,
  SecurityKeyForm = 5,
}

interface SigninState {
  ui_state: UIState
  username?: string
  request_second_factor?: SignInRequestSecondFactor
}

export class SignIn extends React.Component<ThemeProps, SigninState> {
  static contextType = ApiServiceContext
  context: React.ContextType<typeof ApiServiceContext>;

  constructor(props: ThemeProps) {
    super(props)
    this.state = {
      ui_state: UIState.UsernameForm,
    }
  }
  handleSubmitUsername(username: string) {
    const api = this.context!
    api.SignInUsername(username)
      .then((res: SignInResponse) => {
        if (!res.error && res.request_password !== undefined) {
          this.setState({
            ui_state: UIState.PasswordForm,
            username: res.request_password.username,
          })
        }
      });
  }

  handleSubmitPassword(password: string) {
    const redirect = new URLSearchParams(window.location.search).get("rd") || undefined
    const username = this.state.username!
    console.log("Submit passowrd, redirect", redirect)
    const api = this.context!
    api.SignInPassword({username, password, redirect})
      .then((res: SignInResponse) => {
        console.log("Response", res)
        if (res.success) {
          this.handleSignedIn(res)
        } else if (!res.error && res.request_second_factor !== undefined) {
          this.setState({
            ui_state: UIState.SecondFactorForm,
            request_second_factor: res.request_second_factor
          })
        }
      });
  }

  handleSecurityKeyNotSupported() {
    console.log("Not supported!")
  }

  handleSecurityKeyAssertionError(error: any) {
    console.log("Assertion Error: ", error)
  }

  handleSubmitSecurityCode(code: string) {
    let redirect = new URLSearchParams(window.location.search).get("rd") || undefined
    let token = this.state.request_second_factor!.token
    let username = this.state.username!
    const api = this.context!
    api.SignInSecurityCode({ username, token, code, redirect })
      .then(this.handleSignedIn)
  }

  handleSignedIn(res: SignInResponse) {
    if (!res.success) {
      alert("Failed to log in")
      return
    }
    console.log("Sign in success")
    if (res.success.cookie) {
      document.cookie = res.success.cookie;
    }
    if (res.success.redirect) {
      window.location.replace(res.success.redirect)
    } else {
      window.location.replace("/")
    }
  }

  handleOnSecurityKeyCredential(credential: AssertionCredential) {
    let redirect = new URLSearchParams(window.location.search).get("rd") || undefined
    let token = this.state.request_second_factor!.token
    let username = this.state.username!
    const api = this.context!
    api.SignInWebauthn({ username, token, credential, redirect })
      .then(this.handleSignedIn)
  }

  makeSecurityKeyRequest(): SecurityKeyRequest {
    return this.state.request_second_factor!
  }

  render() {
    const { classes } = this.props;
    const { ui_state } = this.state;
    return (
      <main className={classes.dialog}>
        <CssBaseline />
        <Paper className={classes.paper}>
          {ui_state === UIState.UsernameForm && <UsernameForm classes={classes} onSubmit={this.handleSubmitUsername.bind(this)} />}
          {ui_state === UIState.PasswordForm && <PasswordForm classes={classes} username={this.state.username!} onSubmit={this.handleSubmitPassword.bind(this)} />}
          {ui_state === UIState.SecondFactorForm &&
            <SecondFactorForm
              onUseSecurityKey={() => this.setState({ ui_state: UIState.SecurityKeyForm })}
              onUseSecurityCode={() => this.setState({ ui_state: UIState.SecurityCodeForm })}
            />}
          {ui_state === UIState.SecurityCodeForm && <SecurityCodeForm classes={classes} onSubmit={this.handleSubmitSecurityCode.bind(this)} />}
          {ui_state === UIState.SecurityKeyForm && <SecurityKeyForm
            request={this.makeSecurityKeyRequest()}
            onCredential={this.handleOnSecurityKeyCredential.bind(this)}
            onNotSupported={this.handleSecurityKeyNotSupported.bind(this)}
            onAssertionError={this.handleSecurityKeyAssertionError.bind(this)} />}
        </Paper>
      </main>
    );
  }
}
