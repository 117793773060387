import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Enroll } from "./enroll/enroll";
import { Home } from "./home/home";
import { ChangePasswordLoader } from "./password/change_password_loader"
import { SignIn } from "./signin/signin";
import { EnrollCode } from "./enroll/enroll_code"
import { CreateSecurityCode } from "./security_codes/create_security_code"
import { ConfirmAuthorizeKey } from "./authorized_keys/confirm_authorized_key"
import { BackendList } from "./backends/list"
import styles from "./theme-styles"
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';
import { ApiServiceContext, realApiService } from './api/api_client';
import { realWebauthnService, WebauthnServiceContext } from './webauthn';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


const theme = createTheme();

const HomeWithStyles = withStyles(styles)(Home);
//const ChangePasswordWithStyles = withStyles(styles)(ChangePassword);
const SignInWithStyles = withStyles(styles)(SignIn);
const EnrollWithStyles = withStyles(styles)(Enroll);
const EnrollCodeWithStyles = withStyles(styles)(EnrollCode);
const BackendListWithStyles = withStyles(styles)(BackendList);
const CreateSecurityCodeWithStyle = withStyles(styles)(CreateSecurityCode);
const ConfirmAuthorizeKeyWithStyle = withStyles(styles)(ConfirmAuthorizeKey);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ApiServiceContext.Provider value={realApiService}>
    <WebauthnServiceContext.Provider value={realWebauthnService}>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Route exact path="/" component={HomeWithStyles} />
            <Route exact path="/password" component={ChangePasswordLoader} />
            <Route exact path="/signin" component={SignInWithStyles} />
            <Route exact path="/enroll/code" component={EnrollCodeWithStyles} />
            <Route exact path="/enroll" component={EnrollWithStyles} />
            <Route exact path="/backends" component={BackendListWithStyles} />
            <Route exact path="/ssh/:id" component={ConfirmAuthorizeKeyWithStyle} />
            <Route exact path="/sc" component={CreateSecurityCodeWithStyle} />
          </div>
        </Router>
      </ThemeProvider>
      </WebauthnServiceContext.Provider>
    </ApiServiceContext.Provider>
  </StyledEngineProvider>

  , document.getElementById('root')
);