import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import React from 'react';
import { ThemeProps } from "../theme";
import UAParser from 'ua-parser-js';
import { UserResponse } from '../api/api_types';

interface SessionCardProps extends ThemeProps {
  userInfo: UserResponse
}

function getIcon(result: IUAParser.IResult) {
  if (result.os.name === "Mac OS") {
    return (<LaptopMacIcon />)
  } else if (result.os.name === "Android") {
    return <PhoneAndroidIcon />
  } else if (result.os.name === "iOS") {
    return <PhoneIphoneIcon />
  }
  return <ComputerIcon />
}

function getText(result: IUAParser.IResult): string {
  if (result.os.name === "Mac OS") {
    return "Mac"
  } else if (result.os.name === "Android") {
    return "Android Phone"
  } else if (result.os.name === "iOS") {
    return "iPhone"
  }
  return "Browser"
}

export const SessionCard = ({ classes, userInfo }: SessionCardProps) =>
  <Card className={classes.card}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Your devices
      </Typography>
      <Typography component="p">
        Devices that are signed in and have been active in the last 30 days.
      </Typography>
      <List className={classes.card_list}>
        {userInfo.sessions.map(session => {
          var parser = new UAParser(session.user_agent);
          var icon = getIcon(parser.getResult())
          var text = getText(parser.getResult())
          return (
            <ListItem>
              <Avatar className={classes.card_list_icon}>
                {icon}
              </Avatar>
              <ListItemText primary={text} secondary={session.accessed_at} />
            </ListItem>)
        })}
      </List>
    </CardContent>

    <CardActions>
      <Button size="small" color="primary">
        Manage Devices
      </Button>
    </CardActions>
  </Card>
