import { btoab, binToStr } from "./utils";
import { AssertionCredential, AttestationCredential, EnrollRequest } from './api/api_types';
import { createContext, useContext } from "react";

interface WebAuthNAssertion {
  id: string;
  type: string;
  response: {
    authenticatorData: ArrayBuffer;
    clientDataJSON: ArrayBuffer;
    signature: ArrayBuffer;
    userHandle: ArrayBuffer | null;
  };
}

interface WebAuthNAttestation {
  id: string;
  type: string;
  response: {
    attestationObject: ArrayBuffer;
    clientDataJSON: ArrayBuffer;
  };
}

export interface WebauthnAssertionRequest {
  challenge: string
  rp_id: string
  credential_ids: string[]
  onCredential: ((credential: AssertionCredential) => void)
  onAssertionError: ((error: any) => void)
  onNotSupported: (() => void)
}

export interface WebauthnAttestationRequest {
  enroll: EnrollRequest
  onCredential: ((credential: AttestationCredential) => void)
  onError: ((error: any) => void)
  onNotSupported: (() => void)
}

export interface WebauthnService {
  startAssertion(request: WebauthnAssertionRequest) : void;
  startAttestation(request: WebauthnAttestationRequest) : void;
}

export const realWebauthnService: WebauthnService = {
  startAssertion(request: WebauthnAssertionRequest) {
    var credentials = request.credential_ids || []
    console.log("Request", request)
    var opts = {
      challenge: btoab(request.challenge),
      rpId: request.rp_id,
      timeout: 60000,
      allowCredentials: credentials.map(id => ({
        id: btoab(id),
        transports: ["usb", "nfc", "ble", "internal"],
        type: "public-key"
      }))
    }
    let navigatorObj: any = window.navigator;
    if (!navigatorObj || !navigatorObj.credentials) {
      request.onNotSupported()
    } else {
      navigatorObj.credentials.get({ "publicKey": opts })
        .then((assertion: WebAuthNAssertion) => {
          const credential: AssertionCredential = {
            id: assertion.id,
            response: {
              authenticatorData: binToStr(assertion.response.authenticatorData),
              clientDataJSON: binToStr(assertion.response.clientDataJSON),
              signature: binToStr(assertion.response.signature),
              userHandle: binToStr(assertion.response.userHandle)
            }
          };
          request.onCredential(credential)
        })
        .catch((error: any) => {
          request.onAssertionError(error)
        })
    }
  },

  startAttestation(request: WebauthnAttestationRequest) : void {
    let user = request.enroll.user
    var opts = {
      rp: request.enroll.rp,
      user: Object.assign({}, user, { id: btoab(user.id) }),
      challenge: btoab(request.enroll.challenge),
      pubKeyCredParams: [
        {
          type: "public-key",
          alg: -7, // ES256
        },
        {
          type: "public-key",
          alg: -257, // RS256
        },
      ],
      timeout: 60000,
      attestation: "none",
    }
    let navigatorObj: any = window.navigator;
    if (!navigatorObj || !navigatorObj.credentials) {
      request.onNotSupported()
    } else {
      navigatorObj.credentials.create({ "publicKey": opts })
        .then((attestation: WebAuthNAttestation) => {
          const credential: AttestationCredential = {
            id: attestation.id,
            response: {
              attestationObject: binToStr(attestation.response.attestationObject),
              clientDataJSON: binToStr(attestation.response.clientDataJSON),
            }
          };
          request.onCredential(credential)
        })
        .catch((error: any) => {
          request.onError(error)
        })
    }
  }


}


export const WebauthnServiceContext = createContext<WebauthnService|undefined>(undefined);

export const useWebauthnService = () => {
  const context = useContext<WebauthnService|undefined>(WebauthnServiceContext)
  if (context === undefined) {
    throw new Error('WebauthnServiceContext must wrap this component')
  }
  return context
}
