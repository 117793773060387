import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProps } from '../theme';

interface UsernameFormState {
  username: string;
}

interface UsernameFormProps extends ThemeProps {
  onSubmit: ((username: string) => void);
}

export class UsernameForm extends Component<UsernameFormProps, UsernameFormState> {
  constructor(props: UsernameFormProps) {
    super(props)
    this.state = { username: "" }
  }
  handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ username: e.target.value });
  }
  handleSubmit(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.props.onSubmit(this.state.username)
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Securely sign in
        </Typography>
          <Typography component="p" variant="body1">
            Enter your e-mail address and password to continue
          </Typography>
          <TextField
            autoFocus
            fullWidth
            id="username"
            label="E-mail address"
            margin="normal" variant="outlined"
            value={this.state.username} onChange={this.handleUsernameChange.bind(this)} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={this.handleSubmit.bind(this)}
            className={classes.submit}>Next</Button>
      </React.Fragment>
    )
  }
}
