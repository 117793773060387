import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { PasswordCard } from "../password/password_card";
import { SecurityKeysCard } from "../security_keys/security_keys_card";
import { SecurityCodesCard } from "../security_codes/security_codes_card";
import { SessionCard } from "../session/session_card";
import { ThemeProps } from "../theme";
import { Redirect } from 'react-router-dom';
import { ApiServiceContext } from '../api/api_client';
import { UserResponse } from '../api/api_types';

interface HomeState {
  not_authorized?: boolean
  userInfo?: UserResponse
}

interface HomeWithInfoProps extends ThemeProps {
  userInfo: UserResponse
}

class HomeWithInfo extends React.Component<HomeWithInfoProps, any> {
  render() {
    const { classes, userInfo } = this.props;
    return (<div>
      <Typography gutterBottom variant="h4" component="h4">
        Welcome, {userInfo.display_name}
      </Typography>
      <Typography component="p">
        Manage your credentials and security settings here
      </Typography>
      <div className={classes.grid_root}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <PasswordCard classes={classes} userInfo={userInfo} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SecurityKeysCard classes={classes} userInfo={userInfo} />
            <SecurityCodesCard classes={classes} userInfo={userInfo} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SessionCard classes={classes} userInfo={userInfo} />
          </Grid>
        </Grid>
      </div>
    </div>)
  }
}

export class Home extends React.Component<ThemeProps, HomeState> {
  static contextType = ApiServiceContext
  context: React.ContextType<typeof ApiServiceContext>;
  
  constructor(props: ThemeProps) {
    super(props);
    this.state = {
      userInfo: undefined
    }
  }

  componentDidMount() {
    const api = this.context!
    api.GetUser()
      .then(userInfo => {
        console.log("USERINFO", userInfo)
        this.setState({ userInfo })
      })
  }

  render() {
    const { classes } = this.props;
    const { userInfo, not_authorized } = this.state
    return (
      <main className={classes.main}>
        <CssBaseline />
        {not_authorized && (<Redirect to="/signin?rd=/" />)}
        {userInfo && <HomeWithInfo classes={classes} userInfo={userInfo} />}
        {!userInfo && <p>Loading...</p>}
      </main>);
  }
}
