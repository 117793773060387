import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProps } from "../theme";
import { useWebauthnService } from '../webauthn';
import { Paper, Avatar, Typography } from '@mui/material';
import { GenerateSecurityCodeResponse } from '../api/api_types';
import { useApiService } from '../api/api_client';

export const CreateSecurityCode = ({ classes }: ThemeProps) => {
  const [code, setCode] = useState("--------");
  const api = useApiService()
  const webauthn = useWebauthnService()

  useEffect(() => {
    api.RequestSecurityCode()
      .then(res => {
        const { challenge, rp_id, credential_ids, token } = res
        webauthn.startAssertion({
          challenge, rp_id, credential_ids,
          onCredential: (credential => {
            api.GenerateSecurityCode({ token, credential })
              .then((res: GenerateSecurityCodeResponse) => {
                setCode(res.result!.code)
              });
          }),
          onNotSupported: () => { },
          onAssertionError: (error) => { },
        })
      })
  }, [api, webauthn]);

  const formattedCode = code.substring(0, 4) + " " + code.substring(4)
  return (
    <main className={classes.dialog}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Security Code
        </Typography>
        <Typography component="p" variant="body1">
          This 8-digit security code is valid for 24 hours and can only be used once.
        </Typography>
        <Typography component="h1" variant="h3">
          {formattedCode}
        </Typography>
      </Paper>
    </main>
  );
}
