import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';

const CS = (theme: Theme) => createStyles({
  dialog: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    padding: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  textbutton: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
    backgroundColor: "#4285f4",
  },
  spinner: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.secondary.main,
  },
  card: {

  },
  media: {
    height: 140,
  },
  card_list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  card_list_icon: {
    backgroundColor: "#e91e63",
  },
  security_key_icon: {
    backgroundColor: "#00000000",
    color: "#AAA",
  },
  grid_root: {
    flexGrow: 1,
  }
});

export default CS;