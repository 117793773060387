import { TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import React, { Component } from 'react';

interface EnrollFinalizeState {
  name: string;
}

export interface EnrollFinalizeSubmitDetails {
  name: string;
}

interface EnrollFinalizeProps {
  classes: {
    main: string;
    paper: string;
    avatar: string;
    form: string;
    submit: string;
    spinner: string;
  };
  error?: string
  onSubmit: ((details: EnrollFinalizeSubmitDetails) => void);
}

export class EnrollFinalize extends Component<EnrollFinalizeProps, EnrollFinalizeState> {
  constructor(props: EnrollFinalizeProps) {
    super(props)
    this.state = { name: "" }
  }
  handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: e.target.value });
  }
  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enroll new key
        </Typography>
        <form className={classes.form} method="POST" onSubmit={this.handleSubmit.bind(this)}>
          <TextField
            required
            fullWidth
            id="name"
            label="Name"
            margin="normal" variant="outlined"
            value={this.state.name} onChange={this.handleNameChange.bind(this)} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>Add Key</Button>
        </form>
      </Paper>
    )
  }
}
